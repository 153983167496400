/* Write your CSS here */
.main-home-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

.home-container {
  justify-content: center;
  display: flex;
}

.home-container-light {
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #E5EBF0; 
  flex-grow: 1; 
  width: 100%;
  padding-bottom: 50px;
}

.home-image {
  width: 100px;
  height: 100px;
}

.home-heading-light {
  color: #000000;
}

.home-container-dark {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #15161A;
  flex-grow: 1;
  width: 100%;
  padding-bottom: 50px;
  /* height: 100%; */
}

.home-heading-dark {
  color: white;
}


