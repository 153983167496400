/* Write your CSS here */
.about-home-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100vw;
  height: 100vh;
}

.about-container-light {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  flex-grow: 1;
  width: 100%;
}

.about-image {
  width: 100px;
  height: 100px;
}

.about-heading-light {
  color: #000000;
}

.about-container-dark {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
  flex-grow: 1;
  width: 100%;
}

.about-heading-dark {
  color: white;
}
