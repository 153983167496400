/* Write your CSS here */
.nav-bar-container-light {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 15px;
    background-color: white;
    border-bottom-style: solid;
    border-bottom-color: #dcdcdc;
    border-bottom-width: 2px;
    height: 60px;
  }
  
  .website-logo {
    width: 40px;
    height: 40px;
  }
  
  .middle-items {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: right;
    list-style-type: none;
    padding: 0px;
    width: 100%;
  }
  
  .list-item {
    font-size: 16px;
    margin-right: 10px;
    font-weight: bold;
  }
  
  .link-light {
    color: #171f46;
    text-decoration: none;
  }
  
  .theme-button {
    width: 40px;
    height: 40px;
    background-color: transparent;
    border-style: none;
    outline: none;
  }
  
  .theme-img {
    width: 40px;
    height: 40px;
  }
  
  .nav-bar-container-dark {
    -webkit-animation: fadein 0.25s;
    animation: fadein 0.25s;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 15px;
    background-color: #0D0E11;
    height: 60px;
  }
  
  .link-dark {
    color: white;
    text-decoration: none;
  }

  .logo-container {
    margin-right: 100px;
    flex-shrink: 1;
    display: flex;
  }