.project-container {
    background-color: #ffffff;
    display: flex;
    width: 75%;
    text-align: center;
    align-self: center;
    border-radius: 15px;
    margin-top: 50px;
    /* margin-bottom: 25px; */
}

input {
  border: 1px solid #4F279C;
  border-radius: 5px;
  padding: 10px;
  outline: none;
  background-color: #f7f7f7;
}

.card-container {
    background-color: #ffffff;
    width: 100%;
    text-align: center;
    align-self: center;
    padding: 20px;
    border-radius: 15px;
    /* margin-bottom: 20px; */
  }

.card-container-dark {
    -webkit-animation: fadein 0.25s;
    animation: fadein 0.25s;
    background-color: #1D1E23;
    width: 100%;
    text-align: center;
    align-self: center;
    padding: 20px;
    border-radius: 15px;
    /* margin-bottom: 20px; */
  }

  .card-textbox-container {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .card-textbox {
    height: 30px;
  }

  .card-textbox-label {
    width: 100px;
    margin-right: 10px;
    display: inline-block;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.card-btn {
  margin-left: 10px;
  margin-right: 10px;
}

.dark-font {
  color: #ffffff;
}

.refresh-status {
  width: 25px;
  margin-left: 10px;
}